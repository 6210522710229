import { useState, useEffect } from "react";
import { getBanner } from "services/banner";
import logo from 'assets/logo.png';

const Home = () => {
  const [banner, setBanner] = useState({});

  const handleBanner = async () => {
    const data = await getBanner();
    setBanner(data);
  };

  useEffect(() => {
    handleBanner();
  }, []);

  return (
    <>
      <div className="banner__container">
        {banner.fields && (
          <img
            className="banner__img"
            src={banner.fields.image.fields.file.url}
            alt={banner.fields.description}
          />
        )}
        {/* <div className="banner__gradient" /> */}
        <div className="home__info">
          <div className="home__logo">
           <img src={logo} alt="POT BLESS YOU" />
          </div>
          <p className="home__text">
            Diseño y mantenimiento de ecosistemas
          </p>
          <p className="home__text">Rental de plantas</p>
          <p className="home__text">Ambientaciones</p>
          <div className="home__contacts--mobile onlyMobile">
            <a
              href="https://www.instagram.com/potblessyou/"
              target="_blank"
              rel="noreferrer"
              className="home__link"
            >
              <i className="home__contact icon-instagram" />
            </a>
            <a
              href="https://wa.link/v0f1eg"
              target="_blank"
              rel="noreferrer"
              className="home__link"
            >
              <i className="home__contact icon-whatsapp" />
            </a>
            <a
              href="mailto:somospotblessyou@gmail.com"
              target="_blank"
              rel="noreferrer"
              className="home__link"
            >
              <i className="home__contact icon-mail" />
            </a>
          </div>
        </div>
        <div className="home__contacts onlyDesktop">
          <a
            href="https://www.instagram.com/potblessyou/"
            target="_blank"
            rel="noreferrer"
            className="home__link"
          >
            <i className="home__contact icon-instagram" />
          </a>
          <a
            href="https://wa.link/v0f1eg"
            target="_blank"
            rel="noreferrer"
            className="home__link"
          >
            <i className="home__contact icon-whatsapp" />
          </a>
          <a
            href="mailto:somospotblessyou@gmail.com"
            target="_blank"
            rel="noreferrer"
            className="home__link"
          >
            <i className="home__contact icon-mail" />
          </a>
        </div>
      </div>
    </>
  );
};

export default Home;
